import React, { useEffect } from "react";
import Preloader from "./Preloader";
import Sections from "./Sections";
import { useGetGuideEndpoint } from "../_utils";

export default function Guide() {
  const [site, setSite] = React.useState();
  const hhEndpoint = useGetGuideEndpoint();

  useEffect(() => {
    async function fetchSite() {
      try {
        const res = await fetch(hhEndpoint);
        return await res.json();
      } catch (error) {
        console.log(error);
      }
    }
    if (!site) {
      fetchSite()
        .then((resp) => {
          setSite(resp);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [hhEndpoint, site]);

  if (!site) return <Preloader />;

  const { content = {} } = site;
  const { sections = [] } = content;

  return sections.map((s) => {
    return (
      <div style={{ borderBottom: "3px dashed #ccc" }}>
        <Sections sections={[s]} />
      </div>
    );
  });
}
