import React, { useEffect, useState } from "react";
import ActionLink from "./ActionLink";
import IsoTopeGrid from "react-isotope";
import FsLightbox from "fslightbox-react";
import { useViewport } from "./useViewport";

export default function Portfolio({ section }) {
  const { title, tags = [], paragraph1, images = [] } = section;
  const mappedFilters = tags.map((t) => ({ label: t.name, isChecked: false }));
  mappedFilters.unshift({ label: "All", isChecked: true });
  const [filters, updateFilters] = useState(mappedFilters);
  const cards = images.map((i) => ({ id: i.image.id, filter: ["All", i.tag], ...i }));
  const { width } = useViewport();
  let numCol = width >= 1400 ? 4 : width >= 1000 ? 3 : width >= 700 && width < 1000 ? 2 : 1;
  const imageLength = images.length;
  const cntrId = `iso-container-${section.id}`;

  useEffect(() => {
    const floors = Math.ceil(imageLength / numCol);
    var element = document.getElementById(cntrId);
    if (element && element.style) {
      element.style.height = `${floors * 160 + 50}px`;
    }
  }, [numCol, imageLength, cntrId]);

  const onFilter = (value) => {
    updateFilters((state) =>
      state.map((f) => {
        return {
          ...f,
          isChecked: f.label === value,
        };
      })
    );
  };

  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  });

  function openLightboxOnSlide(number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number,
    });
  }

  return (
    <section id="portfolio" className="wrapper">
      <div className="wrapper bg-gray pb-16 pt-4">
        <div className="container py-6 py-md-4 text-center">
          <div className="row">
            <div className="col-lg-10 col-xl-8 col-xxl-7 mx-auto mb-8">
              <h1 className="display-1">{title}</h1>
              <p className="lead fs-lg mr-prewrap">{paragraph1}</p>
            </div>
          </div>

          <div className="grid grid-view projects-masonry">
            <div className="isotope-filter filter mb-10">
              <ul>
                {filters.map((f) => (
                  <li key={f.label}>
                    <ActionLink className="filter-item" onClick={() => onFilter(f.label)}>
                      {f.isChecked ? <b>{f.label}</b> : <span>{f.label}</span>}
                    </ActionLink>
                  </li>
                ))}
              </ul>
            </div>
            <div id={cntrId}>
              <IsoTopeGrid gridLayout={cards} noOfCols={numCol} unitWidth={300} unitHeight={160} filters={filters} className={{}}>
                {true &&
                  cards.map((card, indx) => {
                    const { image = {} } = card;
                    return (
                      <div className={`project u-link item col-md-6 col-xl-4 card ${card.filter[0]}`} key={card.id}>
                        <figure className="overlay overlay-1 rounded" onClick={() => openLightboxOnSlide(indx + 1)}>
                          <img src={image.url} alt="" />
                          <figcaption>
                            <h5 className="from-top mb-0">{card.title}</h5>
                          </figcaption>
                        </figure>
                      </div>
                    );
                  })}
              </IsoTopeGrid>
            </div>
          </div>
        </div>
      </div>
      <FsLightbox slide={lightboxController.slide} type="image" toggler={lightboxController.toggler} sources={images.map((i) => i.image.url)} />
    </section>
  );
}
