import React from "react";

export default function News({ section }) {
  const { articles = [] } = section;

  return (
    <section className="wrapper bg-light">
      <div className="container pb-14 pb-md-16">
        <div className="row">
          <div className="col-lg-10 mx-auto">
            <div className="blog grid grid-view">
              <div className="row isotope gx-md-8 gy-8 mb-8">
                {articles.map((a, i) => {
                  const { image = {} } = a;
                  return (
                    <article className="item post col-md-6" key={a.title}>
                      <div className="card">
                        <figure className="card-img-top overlay overlay-1 hover-scale">
                          <img src={image.url} style={{ height: "320px" }} alt="" />
                        </figure>
                        <div className="card-body">
                          <div className="post-header">
                            <h2 className="post-title h3 mt-1 mb-3">
                              <div className="link-dark u-link">{a.title}</div>
                            </h2>
                          </div>
                          <div className="post-content">
                            <p>{a.content}</p>
                          </div>
                        </div>
                        <div className="card-footer">
                          <ul className="post-meta d-flex mb-0">
                            <li className="post-date">
                              <i className="uil uil-calendar-alt"></i>
                              <span>{a.date}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </article>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
