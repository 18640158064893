import React from "react";

export default function BannerAlt3({ section }) {
  const { image = {}, paragraph1, title } = section;
  return (
    <>
      <section className="wrapper bg-soft-primary">
        <div className="container pt-10 pb-19 pt-md-14 pb-md-20 text-center">
          <div className="row">
            <div className="col-md-8 col-lg-7 col-xl-6 col-xxl-5 mx-auto mb-11">
              <h1 className="display-1 mb-3">{title}</h1>
              <p className="lead px-lg-7 px-xl-7 px-xxl-6 mr-prewrap">{paragraph1}</p>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper bg-light">
        <div className="container pb-14 pb-md-16">
          <div className="row">
            <div className="col-12">
              <article className="mt-n20">
                <figure className="rounded mb-8 mb-md-12">
                  <img src={image.url} alt="" />
                </figure>
              </article>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
