import React from "react";

export default function BannerAlt4({ section }) {
  const { title, paragraph1, paragraph2, heading, image = {} } = section;

  return (
    <>
      <section className="wrapper bg-dark text-white">
        <div className="container pt-18 pt-md-20 pb-21 pb-md-21 text-center">
          <div className="row">
            <div className="col-sm-10 col-md-8 col-lg-6 col-xl-6 col-xxl-5 mx-auto pt-15">
              <h1 className="display-1 text-white mb-3">{title}</h1>
              <p className="lead px-xl-10 px-xxl-10">{paragraph1}</p>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper bg-light">
        <div className="container">
          <div className="row">
            <div className="col mt-n19 mb-16">
              <div className="card shadow-lg">
                <div className="row gx-0">
                  <div className="col-lg-6 image-wrapper bg-image bg-cover rounded-top rounded-lg-start d-none d-md-block" style={{ backgroundImage: `url(${image.url})` }}></div>
                  <div className="col-lg-6">
                    <div className="p-10 p-md-11 p-lg-13">
                      <h2 className="display-4 mb-3">{heading}</h2>
                      <p className="lead fs-lg">{paragraph2}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
