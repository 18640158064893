import React, { useEffect, useState } from "react";
import { Chart as ChartJS, LinearScale, ArcElement, CategoryScale, BarElement, PointElement, LineElement, Legend, Tooltip, BarController } from "chart.js";
import { Chart } from "react-chartjs-2";
import { Doughnut } from "react-chartjs-2";
import moment from "moment";

ChartJS.register(ArcElement, LinearScale, CategoryScale, BarElement, PointElement, LineElement, Legend, Tooltip, BarController);

export default function Flow() {
  const [waterData, setWaterData] = useState([]);
  const [flow, setFlow] = useState(0);
  const [endDate, setEndDate] = useState(moment());

  React.useEffect(() => {
    const queryFlow = () => {
      const tailraceFlowEndpointLive = " https://apis.genco.net.nz/hydrologyexternal/v2/EWWPT?measurement=MangahaoTailraceFlow";
      async function fetchData() {
        try {
          const res = await fetch(tailraceFlowEndpointLive, {
            method: "GET",
            headers: {
              "Ocp-Apim-Subscription-Key": "3fcf680ed07444dbb23d67eae0a31b86",
            },
          });

          return await res.json();
        } catch (error) {
          console.log(error);
        }
      }
      fetchData()
        .then((resp) => {
          resp && Array.isArray(resp) && typeof resp[0].Value === "number" && setFlow(resp[0].Value);
        })
        .catch((e) => {
          console.log(e);
        });
    };

    const interval = setInterval(() => {
      queryFlow();
    }, 60000);
    queryFlow();
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const formatDate = (d) => {
      const formatInt = (i) => (i < 10 ? `0${i}` : i);
      return `${d.year()}-${formatInt(d.month() + 1)}-${formatInt(d.date())}T${formatInt(d.hour())}:${formatInt(d.minutes())}:00`;
    };

    const startDate = moment(endDate);
    startDate.add(-10, "days").startOf("day");
    const measurement = "MangahaoTailraceFlow";
    //const apiDateFormat = "YYYY-MM-DDThh:mm:ss";
    const tailraceFlowEndpointDateRange = `https://apis.genco.net.nz/hydrologyexternal/v2/EWWPT?measurement=${measurement}&dateFrom=${formatDate(startDate)}&dateTo=${formatDate(endDate)}`;

    async function fetchData() {
      try {
        const res = await fetch(tailraceFlowEndpointDateRange, {
          method: "GET",
          headers: {
            "Ocp-Apim-Subscription-Key": "3fcf680ed07444dbb23d67eae0a31b86",
          },
        });

        return await res.json();
      } catch (error) {
        console.log(error);
      }
    }
    fetchData()
      .then((resp) => {
        resp && Array.isArray(resp) && setWaterData(resp);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [endDate]);

  const dayBuckets = waterData.reduce((buckets, flowData) => {
    const parsedDate = new Date(flowData.TimeStamp);
    const bucket = buckets.find((b) => b.date.getFullYear() === parsedDate.getFullYear() && b.date.getMonth() === parsedDate.getMonth() && b.date.getDate() === parsedDate.getDate());

    if (bucket) {
      bucket.flows.push(flowData);
    } else {
      buckets.push({
        date: new Date(parsedDate.getFullYear(), parsedDate.getMonth(), parsedDate.getDate()),
        flows: [flowData],
      });
    }

    return buckets;
  }, []);

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        suggestedMax: 18,
      },
    },
  };

  let flowData = [];
  let flowBackground = [];
  const flowLabel =
    flow > 20 ? (
      <div className="text-danger text-center">Very High Flow</div>
    ) : flow > 13 ? (
      <div className="text-success text-center">High Flow</div>
    ) : flow > 8 ? (
      <div className="text-success text-center">Normal flow</div>
    ) : flow > 5 ? (
      <div className="text-warning text-center">Low flow</div>
    ) : (
      <div className="text-secondary text-center">Not Flowing</div>
      //Comments to stop break
    );

  const fc = "#F36F21";
  const nc = "#eee";
  const dc = "#fff";

  if (flow <= 10 && flow > 0) {
    flowData.push(10);
    flowData.push(4);
    flowData.push(flow);
    flowData.push(10 - flow);
    flowBackground = [nc, dc, fc, nc];
  } else if (flow <= 20 && flow > 10) {
    flowData.push(flow - 10);
    flowData.push(20 - flow);
    flowData.push(4);
    flowData.push(10);
    flowBackground = [fc, nc, dc, fc];
  } else if (flow > 20) {
    flowData = [10, 4, 10];
    flowBackground = [fc, dc, fc];
  } else {
    flowData = [10, 4, 10];
    flowBackground = [nc, dc, nc];
  }

  const setMaxDate = (endDateStr) => {
    setEndDate(moment(endDateStr).endOf("day"));
  };

  const currentFlowData = {
    datasets: [
      {
        data: flowData,
        backgroundColor: flowBackground,
        borderWidth: 0,
      },
    ],
  };

  return (
    <section className="wrapper bg-light">
      <div className="container pb-20 pb-md-17">
        <div className="py-5">
          <h1>Tailrace flow guage</h1>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="dcontainer pb-10">
              <Doughnut
                data={currentFlowData}
                options={{
                  maintainAspectRatio: false,
                  cutout: 130,
                  events: [],
                }}
              />
              <div className="label">
                <div style={{ fontSize: "4rem" }}>{flow.toFixed(2)}</div>
                <div className="text-center"></div>
                {flowLabel}
              </div>
            </div>
          </div>
          <div className="col-md-6 d-none d-md-block">
            <table className="table">
              <thead>
                <tr>
                  <th colSpan={2}>Historical flows</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Date selection</td>
                  <td>
                    <div className="form-floating mb-0">
                      <input id="week" type="date" className="form-control" min="2016-01-01" value={endDate.format("YYYY-MM-DD")} max={new Date().toLocaleDateString("en-CA")} onChange={(e) => setMaxDate(e.target.value)} />
                      <label htmlFor="textInputExample">10 days ending</label>
                    </div>
                  </td>
                </tr>
                {false && (
                  <tr>
                    <td>Flow test</td>
                    <td>
                      <input type="Number" max={100} min={0} onChange={(e) => setFlow(e.target.value)} value={flow} />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="d-none d-md-block">
          {dayBuckets
            .sort((d1, d2) => (d1.date > d2.date ? -1 : 1))
            .map((bucket) => {
              const labels = bucket.flows.map((b) => new Date(b.TimeStamp).toLocaleTimeString());
              const data = {
                labels,
                datasets: [
                  {
                    type: "bar",
                    label: "Tailrace flow m3/s",
                    backgroundColor: "#00B3E3",
                    data: bucket.flows.map((b) => new Date(b.Value)),
                    borderColor: "white",
                    borderWidth: 2,
                  },
                ],
              };

              return (
                <div className="pb-10" key={bucket.date.toDateString()}>
                  <h2>{new Date(bucket.date).toDateString()}</h2>
                  <Chart type="bar" options={options} data={data} height={80} />
                </div>
              );
            })}
        </div>
      </div>
    </section>
  );
}
