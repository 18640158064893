import React from "react";
import { Link } from "react-router-dom";

export default function Header({ header, globals }) {
  const { logo, logoAlt, links = [] } = header;
  const [offCanvas, showOffCanvas] = React.useState();
  return (
    <header className="wrapper bg-soft-primary">
      <nav className={`navbar navbar-expand-lg classic transparent ${header.navbarPosition} navbar-${header.navbarTextColour}`}>
        <div className="container flex-lg-row flex-nowrap align-items-center">
          <div className="navbar-brand w-100">
            <Link to="/">
              {header.navbarStartLight ? (
                <>
                  <img className="logo-light" src={logo.url} srcSet={logo.url} alt="" />
                  <img className="logo-dark" src={logoAlt.url} srcSet={logoAlt.url} alt="" />
                </>
              ) : (
                <>
                  <img className="logo-light" src={logoAlt.url} srcSet={logoAlt.url} alt="" />
                  <img className="logo-dark" src={logoAlt.url} srcSet={logoAlt.url} alt="" />
                </>
              )}
            </Link>
          </div>
          <div className={`navbar-collapse offcanvas offcanvas-nav offcanvas-start ${offCanvas ? "show" : ""}`} onClick={() => showOffCanvas(false)}>
            <div className="offcanvas-header d-lg-none">
              <h3 className="text-white fs-30 mb-0">{header.title}</h3>
              <button type="button" className="btn-close btn-close-white" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body ms-lg-auto d-flex flex-column h-100">
              <ul className="navbar-nav">
                {links.map((l) => {
                  const { children } = l;
                  return children && children.length > 0 ? (
                    <li className="nav-item dropdown" key={l.name}>
                      <Link to="/" className="nav-link dropdown-toggle">
                        {l.name}
                      </Link>
                      <ul className="dropdown-menu">
                        {children.map((c) => (
                          <li className="nav-item" key={c.value}>
                            <Link to={c.value} state={{ page: c.value }} className="dropdown-item">
                              {c.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ) : (
                    <li className="nav-item" key={l.name}>
                      <Link to={l.value} state={{ page: l.value }} className="nav-link">
                        {l.name}
                      </Link>
                    </li>
                  );
                })}
              </ul>
              <div className="offcanvas-footer d-lg-none">
                <div>
                  <a href="mailto:first.last@email.com" className="link-inverse">
                    {globals.ContactEmail}
                  </a>
                  <br />
                  {globals.ContactMobile}
                  <br />
                </div>
              </div>
            </div>
          </div>
          <div className="navbar-other ms-lg-4">
            <ul className="navbar-nav flex-row align-items-center ms-auto">
              <li className="nav-item d-lg-none">
                <button className="hamburger offcanvas-nav-btn" onClick={() => showOffCanvas(true)}>
                  <span></span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}
