import React from "react";

export default function Schedule({ section }) {
  const { title, events = [] } = section;

  const formatDate = (date) => {
    if (!date) return "";
    var parsedDate = new Date(date);
    return parsedDate.toDateString();
  };

  const formatTime = (date) => {
    if (!date) return "";
    var parsedDate = new Date(date);
    return parsedDate.toLocaleTimeString();
  };

  return (
    <section className="wrapper bg-light">
      <div className="container pb-15 pb-md-17">
        <div className="pt-3">
          <div className="job-list">
            <h3 className="mb-4">{title}</h3>
            {events.map((e, i) => {
              const { description, end } = e;
              return (
                <div className="card mb-4 lift u-link" key={i}>
                  <div className="card-body p-5">
                    <span className="row justify-content-between align-items-center">
                      <span className="col-lg-6 col-md-12 mb-2 mb-md-0 d-flex align-items-center text-body">
                        {e.eventType === "TC" ? (
                          <span className="avatar bg-purple text-white w-9 h-9 fs-17 me-3">TC</span>
                        ) : (
                          <>
                            {e.eventType === "RC" ? <span className="avatar bg-red text-white w-9 h-9 fs-17 me-3">RC</span> : <span className="avatar bg-green text-white w-9 h-9 fs-17 me-3">FL</span>}
                            {/* Stop line break*/}
                          </>
                        )}
                        {e.title}
                        {description && (
                          <>
                            <span>&nbsp;-&nbsp;</span>
                            {description}
                          </>
                        )}
                      </span>
                      <span className="col-lg-6 col-md-12 text-body d-flex align-items-ctop justify-content-end">
                        <i className="uil uil-clock me-1"></i>

                        <div className="d-flex justify-content-center flex-column">
                          <b>{formatDate(e.start)}</b>
                          <span className="text-secondary">{formatTime(e.start)}</span>
                        </div>

                        {end && (
                          <>
                            <span>&nbsp;-&nbsp;</span>
                            <div className="d-flex justify-content-center flex-column">
                              <b>{formatDate(end)}</b>
                              <span className="text-secondary">{formatTime(end)}</span>
                            </div>
                          </>
                        )}
                      </span>
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}
