import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";

import "swiper/css";

export default function BannerMain({ section }) {
  return (
    <section className="wrapper bg-dark">
      <div className="swiper-container swiper-hero dots-over swiper-container-0">
        <Swiper
          spaceBetween={30}
          slidesPerView={1}
          centeredSlides={true}
          autoplay={{
            delay: 7000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
        >
          <SwiperSlide className="swiper-slide bg-overlay bg-overlay-400 bg-dark bg-image" style={{ backgroundImage: `url(${section.image1.url})` }}>
            <div className="container h-100">
              <div className="row h-100">
                <div className="pt-20 col-md-10 offset-md-1 col-lg-7 offset-lg-0 col-xl-6 col-xxl-5 text-center text-lg-start justify-content-center align-self-center align-items-start">
                  <h2 className="display-1 fs-56 mb-4 text-white animate__animated animate__slideInDown animate__delay-1s">{section.title1}</h2>
                  <p className="lead fs-23 lh-sm mb-7 text-white animate__animated animate__slideInRight animate__delay-2s">{section.paragraph1}</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="bg-overlay bg-overlay-400 bg-dark bg-image" style={{ backgroundImage: `url(${section.image2.url})` }}>
            <div className="container h-100">
              <div className="row h-100">
                <div className="pt-20 col-md-10 col-md-11 col-lg-8 col-xl-7 col-xxl-6 mx-auto text-center justify-content-center align-self-center">
                  <h2 className="display-1 fs-56 mb-4 text-white animate__animated animate__slideInDown animate__delay-1s">{section.title2}</h2>
                  <p className="lead fs-23 lh-sm mb-7 text-white animate__animated animate__slideInRight animate__delay-2s">{section.paragraph2}</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="bg-overlay bg-overlay-400 bg-dark bg-image" style={{ backgroundImage: `url(${section.image3.url})` }}>
            <div className="container h-100">
              <div className="row h-100">
                <div className="pt-20 col-md-10 col-md-10 offset-md-1 col-lg-7 offset-lg-5 col-xl-6 offset-xl-6 col-xxl-5 offset-xxl-6 text-center text-lg-start justify-content-center align-self-center align-items-start">
                  <h2 className="display-1 fs-56 mb-4 text-white animate__animated animate__slideInDown animate__delay-1s">{section.title3}</h2>
                  <p className="lead fs-23 lh-sm mb-7 text-white animate__animated animate__slideInRight animate__delay-2s">{section.paragraph3}</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
}
