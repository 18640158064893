import React from "react";

export default function Sponsors({ section }) {
  const { title, imagesRight, images = [], heading, description } = section;

  return (
    <>
      {imagesRight ? (
        <section className="wrapper bg-light">
          <div className="container pb-14 pb-md-16">
            <div className="row gx-lg-8 gx-xl-12 gy-10 gy-lg-0">
              <div className="col-lg-4 mt-lg-2">
                <h2 className="fs-15 text-uppercase text-muted mb-3">{title}</h2>
                <h3 className="display-4 mb-3 pe-xxl-5">{heading}</h3>
                <p className="lead fs-lg mb-0 pe-xxl-5">{description}</p>
              </div>
              <div className="col-lg-8">
                <div className="row row-cols-2 row-cols-md-4 gx-0 gx-md-8 gx-xl-12 gy-12">
                  {images.map((i) => (
                    <div className="col" key={i.id}>
                      <figure className="px-3 px-md-0 px-xxl-2">
                        <img src={i.url} alt="" />
                      </figure>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className="wrapper bg-light">
          <div className="container pb-14 pb-md-16">
            <h2 className="fs-15 text-uppercase text-muted mb-3">{title}</h2>
            <div className="row gx-lg-8 mb-10 gy-5">
              <div className="col-lg-6">
                <h3 className="display-5 mb-0">{heading}</h3>
              </div>
              <div className="col-lg-6">
                <p className="lead mb-0">{description}</p>
              </div>
            </div>
            <div className="row row-cols-2 row-cols-md-3 row-cols-xl-5 gx-lg-6 gy-6 justify-content-center">
              {images.map((i) => (
                <div className="col" key={i.id}>
                  <div className="card shadow-lg h-100 align-items-center">
                    <div className="card-body align-items-center d-flex px-3 py-6 p-md-8">
                      <figure className="px-md-3 px-xl-0 px-xxl-3 mb-0">
                        <img src={i.url} alt="" />
                      </figure>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      )}
    </>
  );
}
