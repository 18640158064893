import React from "react";

export default function DefHero({ section }) {
  const { title, link, paragraph1 } = section;

  return (
    <section className="wrapper bg-soft-primary">
      <div className="container pt-10 pb-19 pt-md-14 pb-md-22 text-center">
        <div className="row">
          <div className="col-md-10 col-lg-8 col-xl-7 mx-auto">
            <div className="post-header">
              {link && (
                <div className="post-category text-line">
                  <a href={link.url} className="hover" rel="category">
                    {link.title}
                  </a>
                </div>
              )}
              <h1 className="display-1 mb-3">{title}</h1>
              <p className="lead px-md-12 px-lg-12 px-xl-15 px-xxl-18 mr-prewrap">{paragraph1}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
