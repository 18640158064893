import React, { useState } from "react";
import { Parallax } from "react-parallax";
import ActionLink from "./ActionLink";
import VideoModal from "./VideoModal";

export default function SiteParallax({ section }) {
  const { title, image, link } = section;
  const [showModal, setShowModal] = useState();

  return (
    <>
      {showModal && <VideoModal videoId={link} close={() => setShowModal(false)} />}
      <section className="wrapper">
        <div className="py-15">
          <Parallax blur={0} bgImage={image.url} bgImageAlt="Whitehotwater" strength={500}>
            <div className="container py-18 text-center">
              <div className="row">
                <div className="col-lg-10 col-xl-10 col-xxl-8 mx-auto">
                  <ActionLink className="btn btn-circle btn-white btn-play ripple mx-auto mb-5" onClick={() => setShowModal(true)}>
                    <i className="icn-caret-right"></i>
                  </ActionLink>
                  <h2 className="display-4 px-lg-10 px-xl-13 px-xxl-10 mb-10 text-white">{title}</h2>
                </div>
              </div>
            </div>
          </Parallax>
        </div>
      </section>
    </>
  );
}
