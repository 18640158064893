import React from "react";
import { Link } from "react-router-dom";

export default function Footer({ footer, globals }) {
  const { title } = globals;

  return (
    <footer className="bg-dark text-inverse">
      <div className="container py-13 py-md-15">
        <div className="row gy-6 gy-lg-0">
          <div className="col-lg-4">
            <div className="widget">
              <h2 className="text-white">{title}</h2>
              <p className="mb-4">© 2022 EWWPT. All rights reserved.</p>
              <nav className="nav social social-white"></nav>
            </div>
          </div>
          <div className="col-md-4 col-lg-3 offset-lg-2">
            <div className="widget">
              <h4 className="widget-title mb-3 text-white">EWWPT</h4>
              <ul className="list-unstyled mb-0">
                <li>
                  <Link to="/home">Home</Link>
                </li>
                <li>
                  <Link to="/about">Venues</Link>
                </li>
                <li>
                  <Link to="/schedule">Events</Link>
                </li>
                <li>
                  <Link to="/news">News</Link>
                </li>
                <li>
                  <Link to="/contact">Contact us</Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-md-8 col-lg-3">
            <div className="widget">
              <h4 className="widget-title mb-3 text-white">Location</h4>
              <div className="mapouter">
                <div className="gmap_canvas">
                  <iframe title="map" style={{ borderRadius: "4px" }} width="400" height="300" id="gmap_canvas" src="https://maps.google.com/maps?q=355%20Mangahao%20Road,%20Shannon%204474&t=&z=15&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
