import React from "react";

export default function Colours({ section }) {
  const { title, colours = [] } = section;

  return (
    <div className="container">
      <div className="colour__list mb-10">
        {colours.map((c) => (
          <div className="colour__item">
            <span className="colour__circle" style={{ backgroundColor: c.backgroundColour }}></span>
            <span>{title}</span>
            <span>TSR: {c.tsr}%</span>
            <span>LRV: {c.lrv}%</span>
          </div>
        ))}
      </div>
    </div>
  );
}
