import React from "react";

export default function BannerAlt({ section }) {
  const { title, paragraph1, image = {} } = section;
  return (
    <section className="wrapper image-wrapper bg-image bg-overlay text-white mb-14" data-image-src={image.url}>
      <div className="container pt-19 pt-md-21 pb-18 pb-md-20 text-center">
        <div className="row">
          <div className="col-md-10 col-lg-8 col-xl-7 col-xxl-6 mx-auto">
            <h1 className="display-1 text-white mb-3">{title}</h1>
            <p className="lead fs-lg px-md-3 px-lg-7 px-xl-9 px-xxl-10 mr-prewrap">{paragraph1}</p>
          </div>
        </div>
      </div>
    </section>
  );
}
