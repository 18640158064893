import React from "react";

export default function Triplet({ section }) {
  const { images } = section;

  return (
    <section className="wrapper bg-light">
      <div className="container pb-14 pb-md-16">
        <div className="row row-cols-2 row-cols-md-3 row-cols-xl-3 gx-lg-6 gy-6 justify-content-center">
          {images.map((i) => (
            <div className="col" key={i.id}>
              <div className="card shadow-lg align-items-center">
                <div className="card-body align-items-center d-flex px-3 py-6 p-md-8">
                  <figure className="px-md-3 px-xl-0 px-xxl-3 mb-0">
                    <img src={i.url} alt="" />
                  </figure>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
