import React from "react";

export default function Blog({ section }) {
  const { title, content, author, date, image = {}, link } = section;

  return (
    <section className="wrapper bg-light mt-3">
      <div className="container pb-12">
        <div className="row">
          <div className="col-lg-10 mx-auto">
            <div className="blog classic-view mt-n17">
              <article className="post">
                <div className="card">
                  <figure className="card-img-top">
                    <img src={image.url} alt="" />
                  </figure>
                  <div className="card-body">
                    <div className="post-header">
                      <h2 className="post-title mt-1 mb-0">
                        <a className="link-dark" href={link}>
                          {title}
                        </a>
                      </h2>
                    </div>
                    <div className="post-content">
                      <p>{content}</p>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="post-meta d-flex mb-0">
                      <div className="post-date me-2">
                        <i className="uil uil-calendar-alt"></i>
                        <span>{date}</span>
                      </div>
                      <div className="post-author">
                        <div className="u-link">
                          <i className="uil uil-user"></i>
                          <span>{author}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
