import React from "react";

export default function DefSplit({ section }) {
  const { title, alignleft, subParagraph, link, paragraph, image = {}, linkText } = section;

  return (
    <section className="wrapper bg-light">
      <div className="container pt-10 pb-10">
        {alignleft ? (
          <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-top">
            <div className="col-lg-6  position-relative">
              <div className="shape rounded-circle bg-soft-primary rellax w-18 h-18" data-rellax-speed="1" style={{ bottom: "-2.5rem", left: "-1.5rem" }}></div>
              <figure className="rounded mb-0">
                <img src={image.url} srcSet={image.url} alt="" />
              </figure>
            </div>
            <div className="col-lg-6">
              <h3 className="display-6 mb-4">{title}</h3>
              <p className="mb-5 mr-prewrap">{paragraph}</p>
              {subParagraph && <p className="mb-5 mr-prewrap">{subParagraph}</p>}
              {link && (
                <a href={link} className="btn btn-primary rounded-pill mt-6 mb-0" target="_blank" rel="noreferrer">
                  {linkText}
                </a>
              )}
            </div>
          </div>
        ) : (
          <div className="row gx-lg-8 gx-xl-12 gy-10 mb-14 mb-md-17 align-items-top">
            <div className="col-lg-6">
              <h3 className="display-6 mb-4">{title}</h3>
              <p className="mb-5 mr-prewrap">{paragraph}</p>
              {link && (
                <a href={link} className="btn btn-primary rounded-pill mt-6 mb-0" target="_blank" rel="noreferrer">
                  {linkText}
                </a>
              )}
            </div>
            <div className="col-lg-6 position-relative">
              <div className="shape bg-dot primary rellax w-17 h-18" data-rellax-speed="1" style={{ bottom: "-2rem", left: "-0.7rem" }}></div>
              <figure className="rounded mb-0">
                <img src={image.url} srcSet={image.url} alt="" />
              </figure>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}
