import React from "react";

export default function Project({ section }) {
  const { title, paragraph1 } = section;
  return (
    <section className="wrapper bg-light">
      <div className="container pb-14 pb-md-16">
        <div className="row">
          <div className="col-12">
            <article>
              <div className="row">
                <div className="col-lg-10 offset-lg-1">
                  <h2 className="display-6 mb-4">{title}</h2>
                  <div className="row gx-0">
                    <div className="col-md-9 text-justify">
                      <p className="mr-prewrap">{paragraph1}</p>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
}
