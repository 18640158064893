import React from "react";
import BannerAlt from "./BannerAlt";
import BannerAlt2 from "./BannerAlt2";
import BannerAlt3 from "./BannerAlt3";
import BannerAlt4 from "./BannerAlt4";
import BannerMain from "./BannerMain";
import Blog from "./Blog";
import Colours from "./Colours";
import Contact from "./Contact";
import DefHeading from "./DefHeading";
import DefHero from "./DefHero";
import DefSplit from "./DefSplit";
import Flow from "./Flow";
import HeadWithLead from "./HeadWithLead";
import News from "./News";
import NewsItem from "./NewsItem";
import SiteParallax from "./Parallax";
import Portfolio from "./Portfolio";
import Project from "./Project";
import Schedule from "./Schedule";
import Sponsors from "./Sponsors";
import Textblock from "./Textblock";
import Triplet from "./Triplet";
import PricingTable from "./PricingTable";

export default function Sections({ sections }) {
  return sections.map((s) => {
    switch (s.type) {
      case "SECTION_BANNERMAIN":
        return <BannerMain section={s} key={s.id} />;
      case "SECTION_BANNERALT":
        return <BannerAlt section={s} key={s.id} />;
      case "SECTION_BANNERALT2":
        return <BannerAlt2 section={s} key={s.id} />;
      case "SECTION_BANNERALT3":
        return <BannerAlt3 section={s} key={s.id} />;
      case "SECTION_BANNERALT4":
        return <BannerAlt4 section={s} key={s.id} />;
      case "SECTION_BLOG":
        return <Blog section={s} key={s.id} />;
      case "SECTION_PROJECT":
        return <Project section={s} key={s.id} />;
      case "SECTION_COLOURS":
        return <Colours section={s} key={s.id} />;
      case "SECTION_CONTACT":
        return <Contact section={s} key={s.id} />;
      case "DEF_HEADING":
        return <DefHeading section={s} key={s.id} />;
      case "DEF_HERO":
        return <DefHero section={s} key={s.id} />;
      case "DEF_SPLIT":
        return <DefSplit section={s} key={s.id} />;
      case "SECTION_HEADWITHLEAD":
        return <HeadWithLead section={s} key={s.id} />;
      case "SECTION_NEWS":
        return <News section={s} key={s.id} />;
      case "SECTION_NEWSITEM":
        return <NewsItem section={s} key={s.id} />;
      case "SECTION_PARALLAX":
        return <SiteParallax section={s} key={s.id} />;
      case "SECTION_PORTFOLIO":
        return <Portfolio section={s} key={s.id} />;
      case "SECTION_SCHEDULE":
        return <Schedule section={s} key={s.id} />;
      case "SECTION_SPONSORS":
        return <Sponsors section={s} key={s.id} />;
      case "SECTION_TEXTBLOCK":
        return <Textblock section={s} key={s.id} />;
      case "SECTION_TRIPLET":
        return <Triplet section={s} key={s.id} />;
      case "SECTION_FLOWS":
        return <Flow section={s} key={s.id} />;
      case "SECTION_PRICINGTABLE":
        return <PricingTable section={s} key={s.id} />;
      default:
        return null;
    }
  });
}
