import React from "react";

export default function BannerAlt2({ section }) {
  const { title, description } = section;

  return (
    <section className="wrapper bg-soft-primary">
      <div className="container pt-10 pb-19 pt-md-14 pb-md-20 text-center">
        <div className="row">
          <div className="col-md-7 col-lg-6 col-xl-5 mx-auto">
            <h1 className="display-1 mb-3">{title}</h1>
            <p className="lead px-lg-5 px-xxl-8">{description}</p>
          </div>
        </div>
      </div>
    </section>
  );
}
