import React from "react";
import { useLocation } from "react-router-dom";

export const TestTailraceFlow = [
  {
    TimeStamp: "2022-10-16T05:30:00.0000000+12:00",
    IsGood: true,
    Value: 10.38844,
  },
  {
    TimeStamp: "2022-10-16T05:45:00.0000000+12:00",
    IsGood: true,
    Value: 7.6694665,
  },
  {
    TimeStamp: "2022-10-16T06:00:00.0000000+12:00",
    IsGood: true,
    Value: 7.60906553,
  },
  {
    TimeStamp: "2022-10-16T06:15:00.0000000+12:00",
    IsGood: true,
    Value: 7.61300325,
  },
  {
    TimeStamp: "2022-10-16T06:30:00.0000000+12:00",
    IsGood: true,
    Value: 7.50946665,
  },
  {
    TimeStamp: "2022-10-16T06:45:00.0000000+12:00",
    IsGood: true,
    Value: 7.63399935,
  },
  {
    TimeStamp: "2022-10-16T07:00:00.0000000+12:00",
    IsGood: true,
    Value: 7.57742262,
  },
  {
    TimeStamp: "2022-10-16T07:15:00.0000000+12:00",
    IsGood: true,
    Value: 7.586519,
  },
  {
    TimeStamp: "2022-10-16T07:30:00.0000000+12:00",
    IsGood: true,
    Value: 7.466501,
  },
  {
    TimeStamp: "2022-10-16T07:45:00.0000000+12:00",
    IsGood: true,
    Value: 7.60716629,
  },
  {
    TimeStamp: "2022-10-16T08:00:00.0000000+12:00",
    IsGood: true,
    Value: 7.53339624,
  },
  {
    TimeStamp: "2022-10-16T08:15:00.0000000+12:00",
    IsGood: true,
    Value: 10.4062195,
  },
  {
    TimeStamp: "2022-10-16T08:30:00.0000000+12:00",
    IsGood: true,
    Value: 10.435214,
  },
  {
    TimeStamp: "2022-10-16T08:45:00.0000000+12:00",
    IsGood: true,
    Value: 10.10647,
  },
  {
    TimeStamp: "2022-10-16T09:00:00.0000000+12:00",
    IsGood: true,
    Value: 8.072329,
  },
  {
    TimeStamp: "2022-10-16T09:15:00.0000000+12:00",
    IsGood: true,
    Value: 7.59675646,
  },
  {
    TimeStamp: "2022-10-16T09:30:00.0000000+12:00",
    IsGood: true,
    Value: 7.54196835,
  },
  {
    TimeStamp: "2022-10-16T09:45:00.0000000+12:00",
    IsGood: true,
    Value: 7.594115,
  },
  {
    TimeStamp: "2022-10-16T10:00:00.0000000+12:00",
    IsGood: true,
    Value: 7.4787,
  },
  {
    TimeStamp: "2022-10-16T10:15:00.0000000+12:00",
    IsGood: true,
    Value: 7.511236,
  },
  {
    TimeStamp: "2022-10-16T10:30:00.0000000+12:00",
    IsGood: true,
    Value: 7.434402,
  },
  {
    TimeStamp: "2022-10-16T10:45:00.0000000+12:00",
    IsGood: true,
    Value: 7.51842833,
  },
  {
    TimeStamp: "2022-10-16T11:00:00.0000000+12:00",
    IsGood: true,
    Value: 7.49344349,
  },
  {
    TimeStamp: "2022-10-16T11:15:00.0000000+12:00",
    IsGood: true,
    Value: 7.52750063,
  },
  {
    TimeStamp: "2022-10-16T11:30:00.0000000+12:00",
    IsGood: true,
    Value: 7.51902771,
  },
  {
    TimeStamp: "2022-10-16T11:45:00.0000000+12:00",
    IsGood: true,
    Value: 7.489334,
  },
  {
    TimeStamp: "2022-10-16T12:00:00.0000000+12:00",
    IsGood: true,
    Value: 7.488961,
  },
  {
    TimeStamp: "2022-10-16T12:15:00.0000000+12:00",
    IsGood: true,
    Value: 7.54,
  },
  {
    TimeStamp: "2022-10-16T12:30:00.0000000+12:00",
    IsGood: true,
    Value: 7.47999763,
  },
  {
    TimeStamp: "2022-10-16T12:45:00.0000000+12:00",
    IsGood: true,
    Value: 7.49033451,
  },
  {
    TimeStamp: "2022-10-16T13:00:00.0000000+12:00",
    IsGood: true,
    Value: 7.572334,
  },
  {
    TimeStamp: "2022-10-16T13:15:00.0000000+12:00",
    IsGood: true,
    Value: 7.46622038,
  },
  {
    TimeStamp: "2022-10-16T13:30:00.0000000+12:00",
    IsGood: true,
    Value: 7.53,
  },
  {
    TimeStamp: "2022-10-16T13:45:00.0000000+12:00",
    IsGood: true,
    Value: 7.565899,
  },
  {
    TimeStamp: "2022-10-16T14:00:00.0000000+12:00",
    IsGood: true,
    Value: 7.599731,
  },
  {
    TimeStamp: "2022-10-16T14:15:00.0000000+12:00",
    IsGood: true,
    Value: 7.48359966,
  },
  {
    TimeStamp: "2022-10-16T14:30:00.0000000+12:00",
    IsGood: true,
    Value: 7.438265,
  },
  {
    TimeStamp: "2022-10-16T14:45:00.0000000+12:00",
    IsGood: true,
    Value: 7.46226835,
  },
  {
    TimeStamp: "2022-10-16T15:00:00.0000000+12:00",
    IsGood: true,
    Value: 7.48775625,
  },
  {
    TimeStamp: "2022-10-16T15:15:00.0000000+12:00",
    IsGood: true,
    Value: 7.499867,
  },
  {
    TimeStamp: "2022-10-16T15:30:00.0000000+12:00",
    IsGood: true,
    Value: 7.60010052,
  },
  {
    TimeStamp: "2022-10-16T15:45:00.0000000+12:00",
    IsGood: true,
    Value: 7.497537,
  },
  {
    TimeStamp: "2022-10-16T16:00:00.0000000+12:00",
    IsGood: true,
    Value: 7.58880138,
  },
  {
    TimeStamp: "2022-10-16T16:15:00.0000000+12:00",
    IsGood: true,
    Value: 7.54859829,
  },
  {
    TimeStamp: "2022-10-16T16:30:00.0000000+12:00",
    IsGood: true,
    Value: 7.63499928,
  },
  {
    TimeStamp: "2022-10-16T16:45:00.0000000+12:00",
    IsGood: true,
    Value: 7.521868,
  },
  {
    TimeStamp: "2022-10-16T17:00:00.0000000+12:00",
    IsGood: true,
    Value: 7.6028,
  },
  {
    TimeStamp: "2022-10-16T17:15:00.0000000+12:00",
    IsGood: true,
    Value: 7.44,
  },
  {
    TimeStamp: "2022-10-16T17:30:00.0000000+12:00",
    IsGood: true,
    Value: 7.632336,
  },
  {
    TimeStamp: "2022-10-16T17:45:00.0000000+12:00",
    IsGood: true,
    Value: 7.537291,
  },
  {
    TimeStamp: "2022-10-16T18:00:00.0000000+12:00",
    IsGood: true,
    Value: 7.500532,
  },
  {
    TimeStamp: "2022-10-16T18:15:00.0000000+12:00",
    IsGood: true,
    Value: 7.38779926,
  },
  {
    TimeStamp: "2022-10-16T18:30:00.0000000+12:00",
    IsGood: true,
    Value: 7.60187435,
  },
  {
    TimeStamp: "2022-10-16T18:45:00.0000000+12:00",
    IsGood: true,
    Value: 7.42793655,
  },
  {
    TimeStamp: "2022-10-16T19:00:00.0000000+12:00",
    IsGood: true,
    Value: 7.417991,
  },
  {
    TimeStamp: "2022-10-16T19:15:00.0000000+12:00",
    IsGood: true,
    Value: 7.475385,
  },
  {
    TimeStamp: "2022-10-16T19:30:00.0000000+12:00",
    IsGood: true,
    Value: 7.626201,
  },
  {
    TimeStamp: "2022-10-16T19:45:00.0000000+12:00",
    IsGood: true,
    Value: 7.544466,
  },
  {
    TimeStamp: "2022-10-16T20:00:00.0000000+12:00",
    IsGood: true,
    Value: 7.52146769,
  },
  {
    TimeStamp: "2022-10-16T20:15:00.0000000+12:00",
    IsGood: true,
    Value: 7.436299,
  },
  {
    TimeStamp: "2022-10-16T20:30:00.0000000+12:00",
    IsGood: true,
    Value: 7.42643166,
  },
  {
    TimeStamp: "2022-10-16T20:45:00.0000000+12:00",
    IsGood: true,
    Value: 7.46591949,
  },
  {
    TimeStamp: "2022-10-16T21:00:00.0000000+12:00",
    IsGood: true,
    Value: 7.54179955,
  },
  {
    TimeStamp: "2022-10-16T21:15:00.0000000+12:00",
    IsGood: true,
    Value: 7.400898,
  },
  {
    TimeStamp: "2022-10-16T21:30:00.0000000+12:00",
    IsGood: true,
    Value: 7.45626736,
  },
  {
    TimeStamp: "2022-10-16T21:45:00.0000000+12:00",
    IsGood: true,
    Value: 7.435399,
  },
  {
    TimeStamp: "2022-10-16T22:00:00.0000000+12:00",
    IsGood: true,
    Value: 7.42413044,
  },
  {
    TimeStamp: "2022-10-16T22:15:00.0000000+12:00",
    IsGood: true,
    Value: 7.448331,
  },
  {
    TimeStamp: "2022-10-16T22:30:00.0000000+12:00",
    IsGood: true,
    Value: 7.51903534,
  },
  {
    TimeStamp: "2022-10-16T22:45:00.0000000+12:00",
    IsGood: true,
    Value: 7.39912844,
  },
  {
    TimeStamp: "2022-10-16T23:00:00.0000000+12:00",
    IsGood: true,
    Value: 7.51983261,
  },
  {
    TimeStamp: "2022-10-16T23:15:00.0000000+12:00",
    IsGood: true,
    Value: 7.43,
  },
  {
    TimeStamp: "2022-10-16T23:30:00.0000000+12:00",
    IsGood: true,
    Value: 7.38649845,
  },
  {
    TimeStamp: "2022-10-16T23:45:00.0000000+12:00",
    IsGood: true,
    Value: 7.46246624,
  },
  {
    TimeStamp: "2022-10-17T00:00:00.0000000+12:00",
    IsGood: true,
    Value: 7.45879269,
  },
  {
    TimeStamp: "2022-10-17T00:15:00.0000000+12:00",
    IsGood: true,
    Value: 7.4866004,
  },
  {
    TimeStamp: "2022-10-17T00:30:00.0000000+12:00",
    IsGood: true,
    Value: 7.472302,
  },
  {
    TimeStamp: "2022-10-17T00:45:00.0000000+12:00",
    IsGood: true,
    Value: 7.42483425,
  },
  {
    TimeStamp: "2022-10-17T01:00:00.0000000+12:00",
    IsGood: true,
    Value: 7.43799734,
  },
  {
    TimeStamp: "2022-10-17T01:15:00.0000000+12:00",
    IsGood: true,
    Value: 7.50300169,
  },
  {
    TimeStamp: "2022-10-17T01:30:00.0000000+12:00",
    IsGood: true,
    Value: 7.51,
  },
  {
    TimeStamp: "2022-10-17T01:45:00.0000000+12:00",
    IsGood: true,
    Value: 7.47819471,
  },
  {
    TimeStamp: "2022-10-17T02:00:00.0000000+12:00",
    IsGood: true,
    Value: 7.47133636,
  },
  {
    TimeStamp: "2022-10-17T02:15:00.0000000+12:00",
    IsGood: true,
    Value: 7.467664,
  },
  {
    TimeStamp: "2022-10-17T02:30:00.0000000+12:00",
    IsGood: true,
    Value: 7.514898,
  },
  {
    TimeStamp: "2022-10-17T02:45:00.0000000+12:00",
    IsGood: true,
    Value: 7.46349859,
  },
  {
    TimeStamp: "2022-10-17T03:00:00.0000000+12:00",
    IsGood: true,
    Value: 7.492532,
  },
  {
    TimeStamp: "2022-10-17T03:15:00.0000000+12:00",
    IsGood: true,
    Value: 7.494199,
  },
  {
    TimeStamp: "2022-10-17T03:30:00.0000000+12:00",
    IsGood: true,
    Value: 7.484264,
  },
  {
    TimeStamp: "2022-10-17T03:45:00.0000000+12:00",
    IsGood: true,
    Value: 7.33234024,
  },
  {
    TimeStamp: "2022-10-17T04:00:00.0000000+12:00",
    IsGood: true,
    Value: 7.478267,
  },
  {
    TimeStamp: "2022-10-17T04:15:00.0000000+12:00",
    IsGood: true,
    Value: 7.47633553,
  },
  {
    TimeStamp: "2022-10-17T04:30:00.0000000+12:00",
    IsGood: true,
    Value: 7.4329977,
  },
  {
    TimeStamp: "2022-10-17T04:45:00.0000000+12:00",
    IsGood: true,
    Value: 7.510999,
  },
  {
    TimeStamp: "2022-10-17T05:00:00.0000000+12:00",
    IsGood: true,
    Value: 7.481102,
  },
  {
    TimeStamp: "2022-10-17T05:15:00.0000000+12:00",
    IsGood: true,
    Value: 7.3803997,
  },
  {
    TimeStamp: "2022-10-17T05:30:00.0000000+12:00",
    IsGood: true,
    Value: 9.378873,
  },
  {
    TimeStamp: "2022-10-17T05:45:00.0000000+12:00",
    IsGood: true,
    Value: 7.484411,
  },
  {
    TimeStamp: "2022-10-17T06:00:00.0000000+12:00",
    IsGood: true,
    Value: 10.0154629,
  },
  {
    TimeStamp: "2022-10-17T06:15:00.0000000+12:00",
    IsGood: true,
    Value: 9.714787,
  },
  {
    TimeStamp: "2022-10-17T06:30:00.0000000+12:00",
    IsGood: true,
    Value: 10.25,
  },
  {
    TimeStamp: "2022-10-17T06:45:00.0000000+12:00",
    IsGood: true,
    Value: 8.42159748,
  },
  {
    TimeStamp: "2022-10-17T07:00:00.0000000+12:00",
    IsGood: true,
    Value: 10.5076,
  },
  {
    TimeStamp: "2022-10-17T07:15:00.0000000+12:00",
    IsGood: true,
    Value: 1.31,
  },
  {
    TimeStamp: "2022-10-17T07:30:00.0000000+12:00",
    IsGood: true,
    Value: 1.34775937,
  },
  {
    TimeStamp: "2022-10-17T07:45:00.0000000+12:00",
    IsGood: true,
    Value: 1.32,
  },
  {
    TimeStamp: "2022-10-17T08:00:00.0000000+12:00",
    IsGood: true,
    Value: 1.33786678,
  },
  {
    TimeStamp: "2022-10-17T08:15:00.0000000+12:00",
    IsGood: true,
    Value: 1.320665,
  },
  {
    TimeStamp: "2022-10-17T08:30:00.0000000+12:00",
    IsGood: true,
    Value: 1.2259618,
  },
  {
    TimeStamp: "2022-10-17T08:45:00.0000000+12:00",
    IsGood: true,
    Value: 0.3,
  },
  {
    TimeStamp: "2022-10-17T09:00:00.0000000+12:00",
    IsGood: true,
    Value: 0.31,
  },
  {
    TimeStamp: "2022-10-17T09:15:00.0000000+12:00",
    IsGood: true,
    Value: 0.30736652,
  },
  {
    TimeStamp: "2022-10-17T09:30:00.0000000+12:00",
    IsGood: true,
    Value: 0.3327002,
  },
  {
    TimeStamp: "2022-10-17T09:45:00.0000000+12:00",
    IsGood: true,
    Value: 0.337233037,
  },
  {
    TimeStamp: "2022-10-17T10:00:00.0000000+12:00",
    IsGood: true,
    Value: 0.34,
  },
  {
    TimeStamp: "2022-10-17T10:15:00.0000000+12:00",
    IsGood: true,
    Value: 0.347099781,
  },
  {
    TimeStamp: "2022-10-17T10:30:00.0000000+12:00",
    IsGood: true,
    Value: 0.3329335,
  },
  {
    TimeStamp: "2022-10-17T10:45:00.0000000+12:00",
    IsGood: true,
    Value: 0.33,
  },
  {
    TimeStamp: "2022-10-17T11:00:00.0000000+12:00",
    IsGood: true,
    Value: 0.33,
  },
  {
    TimeStamp: "2022-10-17T11:15:00.0000000+12:00",
    IsGood: true,
    Value: 0.3368332,
  },
  {
    TimeStamp: "2022-10-17T11:30:00.0000000+12:00",
    IsGood: true,
    Value: 0.3467665,
  },
  {
    TimeStamp: "2022-10-17T11:45:00.0000000+12:00",
    IsGood: true,
    Value: 0.33,
  },
  {
    TimeStamp: "2022-10-17T12:00:00.0000000+12:00",
    IsGood: true,
    Value: 0.33,
  },
  {
    TimeStamp: "2022-10-17T12:15:00.0000000+12:00",
    IsGood: true,
    Value: 0.34,
  },
  {
    TimeStamp: "2022-10-17T12:30:00.0000000+12:00",
    IsGood: true,
    Value: 0.327133656,
  },
  {
    TimeStamp: "2022-10-17T12:45:00.0000000+12:00",
    IsGood: true,
    Value: 0.34,
  },
  {
    TimeStamp: "2022-10-17T13:00:00.0000000+12:00",
    IsGood: true,
    Value: 0.34,
  },
];

export function useGetSiteEndpoint() {
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const query = useQuery();
  const siteInstance = process.env.REACT_APP_IS_PROD === "true" ? "" : query.get("instanceId") ? "/instance/" + query.get("instanceId") : "/draft";
  const hhEndpoint = React.useMemo(() => `${process.env.REACT_APP_HH_URL}/sites/${process.env.REACT_APP_SITE_ID}${siteInstance}`, [siteInstance]);
  return hhEndpoint;
}

export function useGetGuideEndpoint() {
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const query = useQuery();
  const siteInstance = process.env.REACT_APP_IS_PROD === "true" ? "" : query.get("instanceId") ? "/instance/" + query.get("instanceId") : "";
  const hhEndpoint = React.useMemo(() => `${process.env.REACT_APP_HH_URL}/sites/${process.env.REACT_APP_SITE_ID}${siteInstance}/guide`, [siteInstance]);
  return hhEndpoint;
}
