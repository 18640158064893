import React from "react";

export default function HeadWithLead({ section }) {
  const { title, paragraph1 } = section;

  return (
    <div className="container pt-14">
      <div className="row mb-8 text-center">
        <div className="col-lg-8 col-xl-7 mx-auto">
          <h2 className="fs-16 text-uppercase text-primary mb-3">{title}</h2>
          {paragraph1 && <h3 className="display-4">{paragraph1}</h3>}
        </div>
      </div>
    </div>
  );
}
